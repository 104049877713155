import React from 'react';
import FaqItem from './faq/FaqItem';
import FaqCategory from './faq/FaqCategory';
import Link from 'next/link';
import { ZendeskAPI } from 'react-zendesk';
import { ROUTES } from '_constants/routes';
import NumberFormat from 'react-number-format';
import { config } from '_config';

const faqList = [
  {
    section: 'Definitions',
    items: [
      {
        header: 'What is a Trademark?',
        text: (
          <>
            A Trademark is a word, phrase, symbol, or device, or any
            combination, used or intended to be used, in commerce to identify
            and distinguish the goods of one manufacturer or seller from goods
            manufactured or sold by others, and to indicate the source of the
            goods. Overall, a trademark is a protection of identification.
            <br />
            <br />A few examples include: Apple for laptops, Sprite for soft
            drinks or Adidas for shoes.
          </>
        ),
      },
      {
        header: 'What is a Service Mark?',
        text: 'A Service Mark is a word, phrase, symbol, or device, or any combination, used or intended to be used, in commerce to, to identify and distinguish the services of a provider from the services of other providers.',
      },
      {
        header: 'What is a "Good"?',
        text: 'A Good is any physical product that can be touched, used, worn or owned.',
      },
      {
        header: 'What is a "Service"?',
        text: 'A Service is any action or task provided for the consumer. Services include activities such as dry cleaning, legal services or banking, for example.',
      },
      {
        header: 'What is a Patent?',
        text: 'A Patent generally protects the functionality and design of an invention. It provides the owner with exclusive rights to manufacture goods or services for 20 years from the stated Patent application filing date.',
      },
      {
        header: 'What is a Copyright?',
        text: "A Copyright will generally protect an artist's work. Common items to be copyrighted include; books, photographs, art, movies and music.",
      },
      {
        header: 'What is a Common Law Trademark?',
        text: 'A Common Law Trademark is whenever someone uses a company name, logo or slogan in the marketplace, even when the mark itself is not registered.',
      },
      {
        header: 'What is a specimen?',
        text: 'A Specimen is a real-world example of how exactly the trademark is used with a good or service. Some examples of appropriate specimens include; tags, product packaging or advertisements for services.',
      },
      {
        header: 'What is an Intent to Use Application?',
        text: 'An Intent to Use Application can serve essentially as a company’s “placeholder” in line and validates its application. A Statement of Use needs to be submitted within 6 months of registering an intent to use trademark. If more time is needed, a maximum of 5 (6-month long)  extensions are available to be requested with good cause.',
      },
      {
        header: 'What is a Statement of Use Application?',
        text: 'A Statement of Use Application is filed to alert the USPTO that the trademark is being used. This activates the mark, following the submission of the  Intent to Use Application.',
      },
      {
        header: 'What does "Use" Mean?',
        text: 'The term "Use" relates to use in commerce. A Trademark that has not yet been used on products or services, but will be in the future is in "Intent to Use" status. When the Trademark becomes "Active" and being used in business a specimen can prove active use of the Trademark.',
      },
      {
        header: 'What is a Certification Mark?',
        text: "Any word, name, symbol, device, or any combination, used, or intended to be used, in commerce by someone other than its owner, to certify regional or other origin, material, mode of manufacture, quality, accuracy, or other characteristics of such person's goods or services, or that the work or labor on the goods or services was performed by members of a union or other organization.",
      },
      {
        header: 'What is a Collective Mark?',
        text: 'A word, phrase, symbol, or device, or any combination, used or intended to be used, in commerce, by members of the associated organization. Symbols associated with membership in a group or organization.',
      },
      {
        header: 'What is disclaiming a part of a mark?',
        text: 'When a group of words is used to describe a mark, the USPTO may attempt to claim some of the individual generic or descriptive terms. Failure to disclaim the portion of requested mark may result in rejection of the application. There is an ability to fight the request to disclaim. Consider seeking legal counsel if choosing to do so.',
      },
      {
        header: 'What is a Declaration of Incontestability?',
        text: 'After a trademark passes 5 years of continued use, the Declaration of Incontestability can be filed which provides further protection under trademark law. This prevents others from challenging various aspects of a trademark.',
      },
      {
        header: 'What is and when would I file under Supplemental Register?',
        text: 'The USPTO may notify an applicant using an Office Action Letter  if their mark needs to be filed in this form. Some instances that may require filing under this form are if the mark is descriptive, a surname or a geographic location.',
      },
      {
        header: 'What is TM, SM and ® signify?',
        text: 'TM, SM and ® are a method to display that the user is claiming trademark rights. TM and SM do not require filing with the Federal Registration to use. The ® symbol can only be used if filed.',
      },
      {
        header: 'What is a drawing?',
        text: 'A drawing is a visual representation on a page that displays the trademark. For applications for a mark that is actively being used, the drawing must show how it is used.',
      },
      {
        header: 'What is a dead or abandoned mark?',
        text: 'An abandoned trademark is one that is no longer pending processing by the USPTO and has either been denied or had an Office Action Letter sent out, that was not responded to by the deadline.',
      },
      {
        header: 'Can an abandoned trademark be revived?',
        text: 'Yes. An abandoned trademark can be revived using the Petition to Revive within 2 months of the Notice of Abandonment being issued.',
      },
      {
        header: 'What is a Trademark Search?',
        text: 'A Trademark Search is done to find other marks with similar pronunciation, spelling or appearance. A search is not required, but is a helpful tool to avoid easily discoverable duplications of other active trademarks.',
      },
      {
        header: 'What is a Common Law Search?',
        text: 'The Common Law Search is a search for records outside of the federal register and pending mark applications. The purpose of this search is to find marks being used that have not been filed with the USPTO.',
      },
      {
        header: 'What is trademark monitoring?',
        text: (
          <>
            Trademark monitoring is provided through a report, alerting of any
            new marks that have either been filed or published that are similar
            to yours.
            <br />
            Trademark Genius offers a Monthly Trademark Monitoring Service to
            maintain vigilance of how your mark is being used and stop
            infringement in its tracks.{' '}
            <Link href={ROUTES.trademarkMonitoring}>
              <a className="linkClass">View Monitoring</a>
            </Link>
          </>
        ),
      },
    ],
  },
  {
    section: 'The Application Process',
    items: [
      {
        header:
          'What things will I need to provide to Trademark Genius to begin my application?',
        text: (
          <>
            <ol>
              <li style={{ fontSize: '14px' }}>The mark that will be used</li>
              <li style={{ fontSize: '14px' }}>
                Owner's/Applicant's legal name and address
              </li>
              <li style={{ fontSize: '14px' }}>
                Applicant's type of legal entity
                <ol
                  style={{ listStyleType: 'lower-alpha', paddingBottom: '0' }}
                >
                  <li style={{ fontSize: '14px', marginLeft: '1em' }}>
                    LLC, Corporation, Sole Proprietorship, etc.
                  </li>
                </ol>
              </li>
              <li style={{ fontSize: '14px' }}>
                Name and address of individual for correspondence
              </li>
              <li style={{ fontSize: '14px' }}>A drawing of the Trademark</li>
              <li style={{ fontSize: '14px' }}>
                The category and class(s) of your good(s)/service
                <ol
                  style={{ listStyleType: 'lower-alpha', paddingBottom: '0' }}
                >
                  <li style={{ fontSize: '14px', marginLeft: '1em' }}>
                    More than one class may be selected, however the more
                    selected the more you will need to pay
                  </li>
                </ol>
              </li>
              <li style={{ fontSize: '14px' }}>
                A description of your good(s)/service
              </li>
              <li style={{ fontSize: '14px' }}>
                A list of goods/services to be covered by the application
              </li>
              <li style={{ fontSize: '14px' }}>
                Date mark first used in commerce + date mark shared anywhere
              </li>
              <li style={{ fontSize: '14px' }}>
                A copy of a specimen
                <ol
                  style={{ listStyleType: 'lower-alpha', paddingBottom: '0' }}
                >
                  <li style={{ fontSize: '14px', marginLeft: '1em' }}>
                    If a copy of the specimen is not available at the time of
                    filing, Trademark Genius will send an email reminder
                  </li>
                </ol>
              </li>
            </ol>
          </>
        ),
      },
      {
        header: 'What and how much is the USPTO Filing fee?',
        text: 'Trademark Genius will collect and pay the government Base application (Sections 1 and 44), per class filing fee of $350. Our pricing includes a base cost of $450 per class, which covers the application filing fee and ensures that we can process your application thoroughly, even in cases where additional information is required.',
      },
      {
        header: 'Am I able to get a refund?',
        text: (
          <p>
            When you place an order, your order begins processing immediately.
            Due to the varying nature of processing times, you may request
            cancellation after your order has been placed - but we cannot
            guarantee that your order will be canceled. You may be able to
            cancel your order by contacting us via email at{' '}
            <a className="linkClass" href={'mailto:' + config.email}>
              {config.email}
            </a>{' '}
            or by calling{' '}
            <a className="linkClass" href={'tel:' + config.phone}>
              <NumberFormat
                value={config.phone}
                displayType={'text'}
                format="#-###-###-####"
              />
            </a>
            .<br />
            <br />
            Orders that our team has already begun reviewing and processing will
            be subject to a cancellation fee of up to $99. Your order is no
            longer refundable once we have submitted it to the USPTO.
          </p>
        ),
      },
      {
        header: 'How long does the USPTO processing take?',
        text: 'The length of time for processing can vary. Per the USPTO, the application will begin to be reviewed in around 3 months time. The review itself takes approximately 1 month to complete. If reviewed and deemed acceptable, the USPTO will publish the mark in the Official Gazette in order to screen for any public challenges to the trademark. Once 30 days have passed and there are no challenges to the mark, it is prepared for registration which generally takes 3 months.',
      },
      {
        header: 'Can I make changes to the mark after it has been filed?',
        text: 'Yes. There are fees involved with changes, they are dependent on how far along the trademark is in the process. If a change is needed after the mark has been published to the Official Gazette, a Post-Publication Amendment will need to be filed. Drastic changes to the trademark itself may require filing a new application instead, along with separate filings fees.',
      },
      {
        header: 'What is an Office Action?',
        text: (
          <>
            If the USPTO has a concern with an application, they will voice the
            concern using a “Office Action” letter. The applicant can either
            choose to respond to the letter or abandon their application. There
            is a deadline on the letter and if it is not replied to by the date
            stated, the application will be abandoned. If an applicant chooses
            to respond to the concern, and this response is still challenged by
            the USPTO, an appeal process can take place with the Trademark Trial
            and Appeal Board (TTAB). The proceedings with TTAB contain complex
            procedural rules that can be difficult to follow, at this point of
            the process seeking an attorney as needed may be advised.
            <br />
            <br />
            Trademark Genius offers an Office Action Response service to aid in
            responding to the USPTO and getting your Trademark back on track for
            registration. View details here:{' '}
            <Link href={ROUTES.officeAction}>
              <a className="linkClass">Office action Service</a>
            </Link>
          </>
        ),
      },
      {
        header:
          'Where can I check the status of my application with the USPTO?',
        text: (
          <>
            The status of your application can be viewed by visiting the USPTO’s
            Trademark Status & Document Retrieval portal, you will need your
            serial number.
            <br />
            <br />
            Trademark Status & Document Retrieval:{' '}
            <a className="linkClass" href="https://tsdr.uspto.gov/">
              https://tsdr.uspto.gov/
            </a>
            <br />
            <br />
            If you have questions regarding your status, inquiry is available in
            a variety of methods on the USPTO contact information page:
            <br />
            <br />
            USPTO Contact Information Page:{' '}
            <a
              className="linkClass"
              href="https://www.uspto.gov/trademarks/contact-trademarks"
            >
              https://www.uspto.gov/trademarks/contact-trademarks
            </a>
          </>
        ),
      },
      {
        header: 'How do I keep my mark protected after registration?',
        text: (
          <>
            In order to maintain trademark protection following registration, a
            Declaration of Continued Use needs to be filed on each of the
            following dates:
            <br />
            <br />
            1. 5th-6th anniversary of Date of Filing (first Declaration of C.
            filed)
            <br />
            2. 9th-10th anniversary of Date of Filing (second Declaration of C.
            filed)
            <br />
            3. Every 10 years of Date of Filing (recurring filing moving
            forward)
            <br />
            <br />
            Trademark Genius offers a Trademark Renewal service to aid in a
            seamless renewal and ensure your Trademark remains properly
            protected.{' '}
            <Link href={ROUTES.fillingRenewal}>
              <a className="linkClass">View Filing a Renewal</a>
            </Link>
          </>
        ),
      },
    ],
  },
  {
    section: 'Basic Questions',
    items: [
      {
        header: 'Why do trademarks get refused?',
        text: (
          <>
            The most common grounds for refusal by the USPTO are the following:
            <ul>
              <li style={{ fontSize: '14px !important' }}>
                Likelihood of Confusion
              </li>
              <li style={{ fontSize: '14px !important' }}>
                Merely Descriptive and Deceptively Misdescriptive
              </li>
              <li style={{ fontSize: '14px !important' }}>
                Geographically Descriptive and Geographically Deceptively
                Misdescriptive
              </li>
              <li style={{ fontSize: '14px !important' }}>Ornamentation</li>
              <li style={{ fontSize: '14px !important' }}>Surname</li>
            </ul>
            For further details on each refusal please visit the official USPTO
            website.
          </>
        ),
      },
      {
        header: 'What does my description need to include?',
        text: 'A good description is short, at least two words but no longer than a sentence. It is important that it is specific, but not too descriptive or vague. The description cannot be the category/class of the mark.',
      },
      {
        header:
          'Why do I need to register a trademark when a common law trademark may already exist?',
        text: 'Common law rights are limited to the geographic location where the mark is used. Registered trademarks are protected nationwide and provide constructive notice of the owner’s claim to the mark as evidence of ownership. Registered trademarks can also file for registration with the U.S. Customs Service to prevent importation of infringing foreign goods.',
      },
      {
        header: 'Who holds the ultimate right to use and registration?',
        text: 'Whoever first uses the trademark in commerce or has filed an Intent to Use Application.',
      },
      {
        header: 'Should I Trademark my logo as well?',
        text: 'Registration of the company name protects the word, phrase, symbol, or device, or any combination, used or intended to be used, in commerce, distinguishing it from other companies. Trademarking a logo protects the appearance of the logo itself, specifically the design. Logos are able to be trademarked however not required. If a logo is to be registered it will need to be done so additionally, with its own processing fees.',
      },
      {
        header: 'What category do I use for my product/service?',
        text: (
          <>
            The USPTO has constructed a list of their approved categories to be
            chosen from. There is the ability to register a mark within multiple
            classes, however separate filing fees apply for each category.
            <br />
            <br />
            USPTO Master List Search:{' '}
            <a
              className="linkClass"
              href="https://idm-tmng.uspto.gov/id-master-list-public.html"
            >
              https://idm-tmng.uspto.gov/id-master-list-public.html
            </a>
          </>
        ),
      },
      {
        header:
          'Is my web address protected or do I need to register it as well?',
        text: 'In most cases, no. Registering a trademark will also protect the web address associated, as long as the address is the trademark name followed by a “.com”.',
      },
      {
        header: 'Do plurals of my trademark need to be registered separately?',
        text: 'No. Plurals do not need to be registered separately due to the strong correlation to the trademark name.',
      },
      {
        header: 'What happens if someone is infringing?',
        text: 'A Cease and Desist letter is to be sent by the trademark owner to the infringing party, demanding to discontinue the use of the trademark. If the infringing party either disregards or denies the cease and desist, an infringement lawsuit is an option to pursue. Consider seeking legal counsel if choosing to do so.',
      },
      {
        header: 'Can I sell, license or assign my mark?',
        text: 'Yes. However, all changes in assignment must be notified to the USPTO.',
      },
      {
        header: 'What is software classified as?',
        text: 'Software is something that can be both a good and a service, what defines the category it is placed in, is the nature of the way it is used. Software that is sold to the consumer as a disc or download falls into the product category. If an action or task is completed, yet the consumer does not keep any of the software used, it would be considered a service.',
      },
      {
        header: 'Can I trademark my book?',
        text: 'The nature of trademarks is to protect the identification of a good or service. To properly protect a story or novel, a copyright is the most adequate method.',
      },
      {
        header: 'Do I have to be a U.S. Citizen to register a mark?',
        text: 'No. U.S. Citizenship is not required, however an active citizenship must be disclosed in the record when filing.',
      },
      {
        header: 'Is a Federal Registration valid outside of the U.S?',
        text: 'No. A registered trademark has protection nationwide in the U.S. only. To obtain registration in other countries, the owner must either have an application pending or an already registered trademark and check if the desired country is a member of the Madrid Protocol. If the country is  a member, an International Application can be filed with the International Bureau of the World Property Intellectual Organization.',
      },
    ],
  },
];
const handleChat = () => {
  ZendeskAPI('webWidget', 'show');
  ZendeskAPI('webWidget', 'open');

  ZendeskAPI('webWidget:on', 'close', function () {
    ZendeskAPI('webWidget', 'hide');
  });
};
const FAQ = ({ leftSide }) => {
  return (
    <div className={'faq ' + (!leftSide ? 'no-left-side' : '')}>
      <div className="content-wrapper">
        {leftSide ? (
          <div className="left-side">
            <h2>FAQs</h2>
            <p>
              Everything you need to know about the trademark. Can't find the
              answer you're looking for?{' '}
              <span
                style={{ color: '#03589C', cursor: 'pointer' }}
                onClick={handleChat}
              >
                Chat with our friendly team!
              </span>
            </p>
            <img
              className="lockup desktop"
              src={`${config.basePath}/faq-lockup.png`}
              alt=""
              layout="fill"
            />
          </div>
        ) : null}
        <div className="right-side">
          <h4>**Disclaimer:**</h4>
          <p>
            The content on this Website is for informational purposes only and
            does not constitute legal advice or create an attorney-client
            relationship. Any attorney involvement in trademark applications is
            limited to administrative tasks for USPTO compliance and does not
            constitute full legal representation. For personalized legal advice,
            consult independent counsel.
          </p>
          <p>
            Trademark Genius makes no guarantees about the accuracy,
            completeness, or currency of the information provided. Use of this
            Website and linked materials is at your own risk, and communications
            are not confidential. By using our services, you acknowledge and
            accept these terms.
          </p>
          <div className="accordion" id="accordionExample">
            {faqList
              ? faqList.map((faqSection, j) => (
                  <FaqCategory
                    faqId={j}
                    key={j}
                    header={faqSection.section}
                    text={
                      faqSection.items
                        ? faqSection.items.map((faqItem, i) => (
                            <FaqItem
                              key={j + '_' + i}
                              faqId={j + '_' + i}
                              header={faqItem.header}
                              text={faqItem.text}
                            />
                          ))
                        : null
                    }
                  />
                ))
              : null}
          </div>
        </div>
        <img
          className="lockup mobile"
          src={`${config.basePath}/faq-lockup.png`}
          alt=""
          layout="fill"
        />
      </div>
    </div>
  );
};

export default FAQ;
